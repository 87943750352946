import React from 'react';
import { Link } from 'gatsby';

import Dropdown from './Dropdown';
import ShopProduct from './ShopProduct';
import ShopIBookProduct from './ShopIBookProduct';

const slugToTitleMap = {
  watch: 'WATCH',
  read: 'READ',
  wear: 'WEAR',
  ibooks: 'iBooks',
  other: 'Other',
};

export default function ShopProductCategory(props) {
  const { category, hideLink, showCategorySelectBox, categories, slug } = props;

  const title = slugToTitleMap[slug] || slug;

  const isIBookCategory = slug === 'iBooks';
  return (
    <div className={`product-category product-${slug}`}>
      <div className="product-head d-flex pd-x-sm">
        <span className="cat-title">{title}</span>
        {hideLink ? null : (
          <Link to={`/product-category/${slug}`} className="cat-link">
            Shop All
          </Link>
        )}
        {showCategorySelectBox && categories && <Dropdown hideLabel options={categories} />}
      </div>
      <div className={`product-list columns d-flex ${isIBookCategory ? 'justify-between ibooks-list' : ''}`}>
        {isIBookCategory
          ? Object.values(category).map((p, index) => (
              <ShopIBookProduct className="border-box books-list" key={index} product={p} catSlug={slug} />
            ))
          : Object.values(category).map((p, index) => (
              <ShopProduct className="cols cols-4 list-item" key={index} product={p} catSlug={slug} />
            ))}
      </div>
    </div>
  );
}
